import React, { useState } from "react"
import { css } from "@emotion/core"

import Menu from "../Menu"
import { colors, mediaQueries, container } from "../../styles"

import Icon from "./Icon"

const TopNav = () => {
  const [isOpen, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!isOpen)

  return (
    <>
      <div
        css={[
          container.max,
          css`
            display: none;
            ${mediaQueries.phoneLarge} {
              width: ${isOpen ? "100vw" : "0"};
              position: ${isOpen ? "fixed" : "absolute"};
              top: 5px;
              right: 20px;
              padding: 0;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              z-index: 4;
            }
          `,
        ]}
      >
        <button
          css={css`
            background-color: transparent;
            padding: 0;
            margin: 0;
            border: none;
            min-height: 25px;
            cursor: pointer;
            :focus {
              outline: none;
            }
          `}
          type="button"
          onClick={toggleOpen}
        >
          <Icon
            fill={isOpen ? colors.lightgray : colors.darkgray}
            isOpen={isOpen}
          />
        </button>
      </div>
      <Menu toggleOpen={toggleOpen} menuOpen={isOpen} />
    </>
  )
}

export default TopNav
