import React from "react"
import { fonts, mediaQueries, colors } from "../../styles/css-utils"
import { css } from "@emotion/core"
import Img from "gatsby-image"
import { Link } from "gatsby"

const container = css`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(4, 25vh);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  font-family: ${fonts.sans};
  background-color: rgb(247, 247, 247);
  ${mediaQueries.phoneLarge} {
    display: none;
  }
  .div1 {
    grid-area: 1 / 1 / 5 / 5;
    background-color: ${colors.lightgray};
    color: ${colors.lightblue};
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 1rem;
    font-size: 1.3rem;
  }
  .div2 {
    grid-area: 1 / 5 / 3 / 11;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .div3 {
    grid-area: 3 / 5 / 5 / 11;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`
const linkStyle = css`
  color: ${colors.deepblue};
  position: relative;
  p {
    font-size: 1.5rem;
    font-weight: 500;
    padding-left: 0.6rem;
    ${mediaQueries.phoneLarge} {
      font-size: 1rem;
      padding-top: 0.3rem;
    }
  }
  p:before {
    content: "";
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    position: relative;
    top: 0.2rem;
    margin-right: 8px;
    background-color: ${colors.deepblue};
    display: inline-block;
    ${mediaQueries.phoneLarge} {
      height: 1rem;
      width: 1rem;
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    background-color: rgb(1, 9, 90);
  }
  &:hover .overlay {
    opacity: 1;
  }
  .text {
    color: white;
    font-size: 1.5rem;
    font-weight: 500;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
`

const SectionFour = ({ gridImageSet }) => (
  <div css={container}>
    {/* Left */}
    <div className="div1">KIYOMIZU ART EDUCATION</div>
    {/* Right Top */}
    <div className="div2">
      {gridImageSet.slice(0, 2).map((item, index) => (
        <Link
          to={index === 0 ? "/studio" : "/schools"}
          css={linkStyle}
          key={item.node.base}
        >
          <Img
            fluid={item.node.childImageSharp.fluid}
            css={css`
              height: 44vh !important;
            `}
          />
          <p>{index === 0 ? "STUDIO" : "学校介绍"}</p>
          <div className="overlay">
            <div className="text">{index === 0 ? "STUDIO" : "学校介绍"}</div>
          </div>
        </Link>
      ))}
    </div>
    {/* Right Bottom */}
    <div className="div3">
      {gridImageSet
        .slice(2)
        .reverse()
        .map((item, index) => (
          <Link
            to={index === 0 ? "/education" : "/brand"}
            css={linkStyle}
            key={item.node.base}
          >
            <Img
              fluid={item.node.childImageSharp.fluid}
              css={css`
                height: 44vh !important;
              `}
            />
            <p>{index === 0 ? "EDUCATION" : "BRAND"}</p>
            <div className="overlay">
              <div className="text">{index === 0 ? "EDUCATION" : "BRAND"}</div>
            </div>
          </Link>
        ))}
    </div>
  </div>
)

export default SectionFour
