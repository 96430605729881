import React from "react"
import { css } from "@emotion/core"
import { fonts, mediaQueries, colors } from "../../styles/css-utils"
import { Link } from "gatsby"
import Footer, { navlinkStyle } from "../Footer/Footer"

const container = css`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(25, 4vh);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  font-family: ${fonts.sans};
  background-color: rgb(247, 247, 247);
  ${mediaQueries.phoneLarge} {
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
  }
  .div1 {
    grid-area: 1 / 1 / 25 / 5;
    background-color: ${colors.lightblue};
    color: white;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 2.8rem;
    justify-content: center;
    flex-direction: column;
    ${mediaQueries.phoneLarge} {
      display: none;
    }
  }
  .div2 {
    grid-area: 1 / 5 / 13 / 11;
    background-color: #efefee;
    color: #19258f;
    font-weight: 500;
    font-size: 2.5rem;
    display: flex;
    justify-content: space-between;
    padding: 1.2rem;
    ${mediaQueries.phoneLarge} {
      grid-area: 1 / 1 / 2 / 2;
      font-size: 1.6rem;
    }
  }
  .div3 {
    grid-area: 13 / 5 / 25 / 11;
    background-color: #282828;
    ${mediaQueries.phoneLarge} {
      grid-area: 2 / 1 / 3 / 2;
    }
  }
`

const SectionFive = () => (
  <div css={container}>
    <div className="div1">
      <div>课程</div>
      <div>
        <Link to="/class/normal" css={navlinkStyle}>
          学部
        </Link>
        ・
        <Link to="/class/master" css={navlinkStyle}>
          大学院
        </Link>
      </div>
    </div>
    <div className="div2">
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          color: ${colors.deepblue};
        `}
      >
        <span>KIYOMIZU</span>
        <span>ART</span>
        <span>EDUCATION</span>
      </div>
      <div
        css={css`
          display: flex;
          align-items: flex-end;
        `}
      >
        <div
          css={css`
            background: url("/images/logo.svg");
            height: 30px;
            width: 58px;
            ${mediaQueries.phoneLarge} {
              height: 15px;
              width: 29px;
              margin-bottom: 12px;
            }
          `}
        ></div>
      </div>
    </div>
    <div className="div3"></div>
    <Footer />
  </div>
)

export default SectionFive
