import React from "react"
import { css, keyframes } from "@emotion/core"
import { fonts, colors } from "../../styles/css-utils"

const flying = keyframes`
  0% {
    padding-top: -10px;
  }
  100% {
    padding-top: 100vh;
  }
`

const ScrollBar = () => (
  <div
    css={css`
      writing-mode: vertical-rl;
      text-orientation: mixed;
      background-color: ${colors.deepblue};
      color: white;
      width: 46px;
      font-family: ${fonts.sans};
      font-size: 34px;
      letter-spacing: 3px;
      white-space: nowrap;
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      animation: ${flying} 8s linear infinite;
      display: flex;
      align-items: center;
    `}
  >
    KIYOMIZU ART EDUCATION
  </div>
)

export default ScrollBar
