import React from "react"
import { css } from "@emotion/core"
import Slider from "react-slick"
import Img from "gatsby-image"
import { Link } from "gatsby"
import VisibilitySensor from "react-visibility-sensor"
import { fonts, mediaQueries, colors } from "../../styles/css-utils"
import TopNav from "../TopNav"

const container = css`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(8, 12.5vh);
  font-family: ${fonts.sans};
  background-color: rgb(247, 247, 247);
  ${mediaQueries.phoneLarge} {
    display: block;
    height: 100vh;
  }
  .div1 {
    grid-area: 1 / 1 / 4 / 5;
    background-color: rgb(218, 216, 215);
    font-weight: 700;
    font-size: 3rem;
    color: ${colors.deepblue};
    display: flex;
    flex-direction: column;
    padding: 0.8rem;
    ${mediaQueries.phoneLarge} {
      height: 50vh;
      display: block;
      padding: 6rem 0 0 2.8rem;
    }
  }
  .div2 {
    grid-area: 1 / 6 / 5 / 11;
    background-color: rgb(247, 247, 247);
    ${mediaQueries.phoneLarge} {
      display: none;
    }
  }
  .div3 {
    grid-area: 4 / 1 / 9 / 5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: ${colors.deepblue};
    background-color: ${colors.normalgray};
    ${mediaQueries.phoneLarge} {
      height: 100vh;
    }
  }
  .div4 {
    grid-area: 5 / 5 / 9 / 11;
    padding-top: 5vh;
    .slick-dots {
      top: -2.3rem;
      right: 1rem;
      text-align: right;
    }
    .slick-dots li button:before {
      font-size: 1rem;
      color: rgb(139, 136, 132);
    }
    .slick-dots li.slick-active button:before {
      color: rgb(1, 9, 90);
    }
    ${mediaQueries.phoneLarge} {
      display: none;
    }
  }
`
export const linkStyle = css`
  color: ${colors.deepblue};
`
const settings = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const SectionTwo = ({ sliderImageSet, setShow }) => {
  const onChange = isVisible => {
    if (isVisible) {
      setShow(false)
    }
  }

  return (
    <VisibilitySensor onChange={onChange}>
      <div css={container}>
        <div className="div1">
          <ul
            css={css`
              display: flex;
              list-style: none;
              ${mediaQueries.phoneLarge} {
                display: none;
              }
            `}
          >
            <li>
              <a href="https://portal.kiyomizuart.com/" css={linkStyle}>
                登陆
              </a>
            </li>
            <li>
              <Link to="/about" css={linkStyle}>
                联系我们
              </Link>
            </li>
          </ul>
          <div>
            <TopNav />
            <div
              css={css`
                display: flex;
                justify-content: center;
                ${mediaQueries.phoneLarge} {
                  display: block;
                }
              `}
            >
              <img
                src="/images/logo.png"
                alt="Logo"
                css={css`
                  max-width: 50%;
                  ${mediaQueries.phoneLarge} {
                    max-width: 80%;
                  }
                `}
              />
            </div>
          </div>
        </div>
        <div className="div2">
          <ul
            css={css`
              display: flex;
              justify-content: space-around;
              font-size: 1.4rem;
              font-weight: 600;
              margin: 0;
              padding: 1rem 0;
              color: ${colors.deepblue};
              list-style: none;

              li {
                position: relative;
              }

              li:before {
                content: "";
                height: 1.4rem;
                width: 1.4rem;
                border-radius: 50%;
                border: 1px solid ${colors.deepblue};
                position: relative;
                top: 4px;
                margin-right: 8px;
                background-color: white;
                display: inline-block;
              }

              li.active:before {
                border: none;
                background-color: ${colors.deepblue};
              }

              ul {
                visibility: hidden;
                opacity: 0;
                position: absolute;
                padding-left: 0;
                left: 0;
                top: 21px;
                display: none;
                background: white;
              }

              li:hover > ul,
              li ul:hover {
                visibility: visible;
                opacity: 1;
                display: block;
                min-width: 250px;
                text-align: left;
                box-shadow: 0px 3px 5px -1px #ccc;
              }

              li ul li {
                clear: both;
                width: 100%;
                text-align: left;
                padding: 10px;
                margin: 0;
                list-style: none;
                transition: all 0.5s ease;
              }

              li ul li:not(:first-of-type) {
                border-top: 1px solid gray;
              }

              li ui li:before {
                border-style: none;
              }

              li ul li:hover {
                transition: all 0.3s ease;
                cursor: pointer;
                color: white;
                background-color: rgb(1, 9, 90);
              }
            `}
          >
            <li className="active">
              <Link to="/" css={linkStyle}>
                清水艺术
              </Link>
            </li>
            <li>
              <Link to="/about" css={linkStyle}>
                关于清水
              </Link>
            </li>
            <li>
              <Link to="/schools" css={linkStyle}>
                学校介绍
              </Link>
              <ul>
                <li>东京五美大</li>
                <li>关西三美大</li>
                <li>国公立五艺大</li>
                <li>综合类高排名院校</li>
                <li>其他院校</li>
              </ul>
            </li>
            <li>
              课程 -{" "}
              <Link to="/class/normal" css={linkStyle}>
                学部
              </Link>
              ・
              <Link to="/class/master" css={linkStyle}>
                大学院
              </Link>
            </li>
          </ul>
        </div>
        <div className="div3">
          <div
            css={css`
              padding: 1.3rem 2.5rem 0;
              font-weight: 600;
              p {
                margin-bottom: 3px;
                line-height: 2.2rem;
              }
              .end {
                margin-top: 33px;
              }
              ${mediaQueries.phoneLarge} {
                p {
                  font-size: 1.2rem;
                  line-height: 2rem;
                }
                padding: 2rem 1.5rem;
              }
            `}
          >
            <p>
              清水艺术株式会社主要涉及升学教育、工作室以及独立品牌三个板块，立足于日本东京，致力以日式艺术文化为基底，构建横跨校园与社会的综合性产业链。
            </p>
          </div>
          <ul
            css={css`
              display: flex;
              justify-content: flex-start;
              padding: 0 1rem;
              margin-bottom: 1.3rem;
              list-style: none;
              font-size: 1.4rem;
              font-weight: 500;
              ${mediaQueries.phoneLarge} {
                display: none;
              }

              li {
                padding: 0 1rem;
                position: relative;
              }

              li:before {
                content: "";
                height: 1.4rem;
                width: 1.4rem;
                border-radius: 50%;
                position: relative;
                top: 3px;
                margin-right: 8px;
                background-color: ${colors.deepblue};
                display: inline-block;
              }

              ul {
                visibility: hidden;
                opacity: 0;
                position: absolute;
                padding-left: 0;
                left: 0;
                top: -108px;
                display: none;
                background: white;
              }

              li:hover > ul,
              li ul:hover {
                visibility: visible;
                opacity: 1;
                display: block;
                min-width: 250px;
                text-align: left;
                box-shadow: 0px 3px 5px -1px #ccc;
              }

              li ul li {
                clear: both;
                width: 100%;
                text-align: left;
                list-style: none;
                padding: 10px 1rem;
                margin: 0;
                transition: all 0.5s ease;
              }

              li ul li:not(:first-of-type) {
                border-top: 1px solid gray;
              }

              li ui li:before {
                border-style: none;
              }

              li ul li:hover {
                color: white;
                background-color: rgb(1, 9, 90);
                transition: all 0.3s ease;
                cursor: pointer;

                &:before {
                  background-color: white;
                }
              }
            `}
          >
            <li>
              <Link to="/education" css={linkStyle}>
                EDUCATION
              </Link>
              <ul>
                <li>本科升学</li>
                <li>硕士博士升学</li>
              </ul>
            </li>
            <li>
              <Link to="/studio" css={linkStyle}>
                STUDIO
              </Link>
              <ul>
                <li>设计工作室</li>
                <li>ACG工作室</li>
              </ul>
            </li>
            <li>
              <Link to="/brand" css={linkStyle}>
                BRAND
              </Link>
            </li>
          </ul>
        </div>
        <div className="div4">
          <Slider {...settings}>
            {sliderImageSet.map(i => (
              <Img
                key={i.node.base}
                fluid={i.node.childImageSharp.fluid}
                css={css`
                  height: 45vh !important;
                `}
              />
            ))}
          </Slider>
        </div>
      </div>
    </VisibilitySensor>
  )
}

export default SectionTwo
