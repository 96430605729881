import React from "react"
import { css } from "@emotion/core"
import { fonts, mediaQueries, colors } from "../../styles/css-utils"

const container = css`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(4, 25vh);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  font-family: ${fonts.sans};
  background-color: rgb(247, 247, 247);
  ${mediaQueries.phoneLarge} {
    display: block;
    height: 100%;
  }
  .div1 {
    grid-area: 1 / 1 / 2 / 5;
    background-color: ${colors.white};
    ${mediaQueries.phoneLarge} {
      display: none;
    }
  }
  .div2 {
    grid-area: 2 / 1 / 5 / 5;
    background-color: ${colors.darkgray};
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 2.8rem;
    font-size: 1.3rem;
    font-weight: 600;
    p {
      line-height: 2rem;
      margin-bottom: 2rem;
    }
    ${mediaQueries.phoneLarge} {
      p {
        font-size: 1rem;
        line-height: 2.2rem;
      }
      padding: 1rem;
      height: 100vh;
    }
  }
  .div3 {
    grid-area: 1 / 5 / 5 / 11;
    background-color: ${colors.normalgray};
    color: ${colors.deepblue};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${mediaQueries.phoneLarge} {
      display: block;
    }
  }
`
const SectionThree = () => (
  <div css={container}>
    <div className="div1"></div>
    <div className="div2">
      <p>
        清水艺术团队均来自于日本东京艺术大学、多摩美
        术大学、武藏野美术大学等顶尖高等艺术院校，由
        中国籍团队成员以及日本籍团队成员共同组成，实 现艺术专业领域全覆盖。
      </p>
      <p>
        团队成员作为教师、设计师、艺术家活跃于各自的
        领域，清水艺术也着力于中日院校以及企业之间能
        够有更多深入交流而开展各类活动，我们在东京， 期待你的加入。
      </p>
    </div>
    <div className="div3">
      <ul
        css={css`
          list-style: none;
          padding: 2.5rem 2rem;

          ${mediaQueries.phoneLarge} {
            padding: 1rem;
            margin-bottom: 0;
            border-bottom: 1px solid #282828;
          }

          li {
            font-size: 1.2rem;
            font-weight: 500;
            line-height: 2.2rem;
            color: ${colors.deepblue};
            cursor: pointer;

            ${mediaQueries.phoneLarge} {
              font-size: 0.8rem;
              line-height: 1.2rem;
              margin-bottom: 0.6rem;
            }
          }

          li:hover {
            background-color: ${colors.deepblue};
            color: ${colors.normalgray};
          }

          li:before {
            content: "";
            background-color: ${colors.normalgray};
            height: 1.4rem;
            width: 1.4rem;
            border-radius: 50%;
            border: 1px solid ${colors.deepblue};
            position: relative;
            top: 4px;
            margin-right: 8px;
            display: inline-block;
            ${mediaQueries.phoneLarge} {
              height: 0.6rem;
              width: 0.6rem;
              top: 0;
              code {
                font-size: 0.8rem;
              }
            }
          }
        `}
      >
        <li>2019.12.21 COMI CUP25 上海新国际博览中心 清水X赤门联合出展</li>
        <li>2019.12.20 中央美术学院 艺术公开讲座 望京校区5-A107</li>
        <li>2019.12.19 北京大学日语培训班 艺术公开讲座 北大燕北园335号3楼</li>
        <li>2019.12.18 中国传媒大学 艺术公开讲座 数字艺术学院324室</li>
        <li>2019.12.17 首都师范大学 艺术公开讲座 本部四楼119室</li>
        <li>2019.08.09 武藏野美术大学造型构想研究科 第三回说明会开催</li>
        <li>2019.08.03 B站艺术留学解惑线上直播专场 ID: 80340</li>
        <li>
          2019.07.11 <code>#日本游学在清水</code>{" "}
          短期ACG动画插画六人研修班圆满成功
        </li>
        <li>
          2019.05.12 「POP子与PIPI美的日常」系列导演: 青木纯先生讲座交流会开催
        </li>
        <li>
          2019.05.04 <code>#KM ATELITER</code> 第九回画展 张亚莉东京个展
        </li>
        <li>2019.04.24 清华大学日本美术留学讲座开催 A441大教室时时线上直播</li>
        <li>
          2019.04.23 北京电影学院日本美术留学/工作经验分享会开催
          动画学院C431教室
        </li>
        <li>
          2019.02.12 <code>#日本游学在清水</code>
          由情书艺术与新东方协同承办的动漫游学体验课程圆满成功
        </li>
        <li>
          2018.12.01 <code>#KM ATELIER</code> 第五回画展 刘家男东京个展
        </li>
      </ul>
    </div>
  </div>
)

export default SectionThree
