import React, { useState } from "react"
import { graphql } from "gatsby"
import ReactFullpage from "@fullpage/react-fullpage"
import useMedia from "use-media"
import {
  SectionOne,
  SectionTwo,
  SectionThree,
  SectionFour,
  SectionFive,
} from "../components/Home"
import ScrollBar from "../components/ScrollBar"
import Layout from "../components/layout"
import "animate.css/animate.min.css"

export default ({ data }) => {
  const [show, setShow] = useState(true)
  const isPhoneLarge = useMedia({ maxWidth: "900px" })

  const sliderImageSet = data.allFile.edges
    .filter(i => i.node.base.includes("slider"))
    .sort(
      (a, b) =>
        a.node.base.split(".")[0].slice(-1) -
        b.node.base.split(".")[0].slice(-1)
    )

  const gridImageSet = data.allFile.edges
    .filter(i => i.node.base.includes("grid"))
    .sort(
      (a, b) =>
        a.node.base.split(".")[0].slice(-1) -
        b.node.base.split(".")[0].slice(-1)
    )

  return (
    <Layout title="主页">
      <ScrollBar />
      {/* Main Content */}
      <div css={{ marginLeft: "46px" }}>
        {isPhoneLarge ? (
          <>
            <SectionTwo sliderImageSet={sliderImageSet} setShow={setShow} />
            <SectionThree />
            <SectionFour gridImageSet={gridImageSet} />
            <SectionFive />
          </>
        ) : (
          <ReactFullpage
            licenseKey="DCFBE422-34074431-BE1BBFAA-7CD5864E"
            render={() => (
              <ReactFullpage.Wrapper>
                {/* Section One */}
                {show && (
                  <div className="section">
                    <SectionOne />
                  </div>
                )}
                {/* Section Two */}
                <div className="section">
                  <SectionTwo
                    sliderImageSet={sliderImageSet}
                    setShow={setShow}
                  />
                </div>
                {/* Section Three */}
                <div className="section">
                  <SectionThree />
                </div>
                {/* Section Four */}
                <div className="section">
                  <SectionFour gridImageSet={gridImageSet} />
                </div>
                {/* Section Five */}
                <div className="section">
                  <SectionFive />
                </div>
              </ReactFullpage.Wrapper>
            )}
          />
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allFile(filter: { relativeDirectory: { regex: "/(slider)|(grid)/" } }) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
