import React from "react"
import { css } from "@emotion/core"
import { mediaQueries } from "../../styles/css-utils"

const SectionOne = () => (
  <div
    css={{
      position: "relative",
      width: "100%",
      height: "100vh",
      [mediaQueries.phoneLarge]: {
        display: "none",
      },
    }}
  >
    <img
      className="animated fadeInUpBig"
      src="https://media.giphy.com/media/J5otVDvl94aV7ae90f/giphy.gif"
      css={{
        height: "100vh",
        width: "100%",
      }}
      alt="intro"
    />
    <div
      className="animated fadeInDownBig"
      css={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "50%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        padding: "3rem 1rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src="/images/logo-light.png"
        alt="Logo"
        css={css`
          max-width: 50%;
          ${mediaQueries.phoneLarge} {
            max-width: 80%;
          }
        `}
      />
    </div>
  </div>
)

export default SectionOne
